.Button {
  @extend .pointer;
  font-family: $futura-pt;
  font-weight: 500;
  white-space: nowrap;
  border: 0.125rem solid transparent;
  background-color: transparent;
  transition: $transition-medium;

  &--style-primary {
    font-size: 1.3rem;
    padding: 0.4rem 1.2rem;

    @media (query(md-up)) {
      font-size: 1.8rem;
      padding: 0.3rem 1.3rem;
    }
  }

  &--style-primary-small {
    font-size: 1.25rem;
    padding: 0.1rem 1.2rem;
  }

  &--style-primary-extra-small {
    font-size: 0.875rem;
    padding: 0.375rem 0.75rem;
  }

  &--style-secondary {
    border: 0.0625rem solid transparent;
    font-size: 0.75rem;
    width: 7rem;
    display: flex;
    justify-content: center;
    padding: 0.2rem;
  }

  &--style-primary-v2 {
    color: color('medium-gray');
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    background-color: color('white');
    padding: 0.4375rem 1.5rem;
    border: 1px solid transparent;
    justify-content: center;

    &:hover {
      color: color('black');
    }

    @media (min-width: 750px) {
      font-size: 1rem;
      padding: 0.5rem 1.5rem;
    }
  }

  &--style-secondary-v2 {
    color: color('medium-gray');
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    background-color: transparent;
    border: 1px solid;
    border-color: color('medium-gray');
    padding: 0.4375rem 1.5rem;
    justify-content: center;

    &:hover {
      color: color('black');
      border-color: color('black');
    }

    @media (min-width: 750px) {
      font-size: 1rem;
      padding: 0.5rem 1.5rem;
    }
  }

  &--style-underline {
    border: 0;
    padding: 0;
    border-bottom: 0.0625rem solid transparent;
  }

  &--style-text {
    border: 0;
    padding: 0;
    background-color: transparent;
  }

  &--font-color-white {
    color: color('white');
  }

  &--font-color-black {
    color: color('black');
  }

  &--font-color-gray {
    color: #555555;
  }

  &--hover-white {
    &:hover {
      color: color('white');
    }
  }

  &--hover-black {
    &:hover {
      color: color('black');
    }
  }

  &--hover-gray {
    &:hover {
      color: #555555;
    }
  }

  &--color-white--responsive-invert {
    border-color: color('white');
    background-color: transparent;
    color: color('white');

    &:hover {
      background-color: color('white');
      color: color('black');
    }
  }

  &--color-white {
    border-color: color('white');
    background-color: color('white');
    // color: #555555;

    // &:hover {
    //   color: color('black');
    // }
  }

  &--color-black--responsive-invert {
    border-color: color('black');
    background-color: transparent;
    color: color('black');

    &:hover {
      background-color: color('black');
      color: color('white');
    }
  }

  &--color-black {
    border-color: color('black');
    background-color: color('black');
    color: color('white');

    &:hover {
      background-color: transparent;
      color: color('black');
    }
  }

  &--color-transparent-white-border--responsive-invert {
    border-color: color('white');
    background-color: color('white');
    color: color('black');

    @media (query(md-up)) {
      background-color: transparent;
      color: color('white');
    }

    &:hover {
      background-color: color('white');
      color: color('black');
    }
  }

  &--color-transparent-gray-border--responsive-invert {
    border-color: color('gray');
    background-color: color('gray');
    color: color('black');

    @media (query(md-up)) {
      background-color: transparent;
      color: color('gray');
    }

    &:hover {
      background-color: color('gray');
      color: color('black');
    }
  }

  &--color-transparent-white-border {
    border-color: color('white');
    background-color: transparent;
    color: color('white');

    &:hover {
      background-color: color('white');
      color: color('black');
    }
  }

  &--color-transparent-black-border--responsive-invert {
    border-color: color('black');
    background-color: color('black');
    color: color('white');

    @media (query(md-up)) {
      background-color: transparent;
      color: color('black');
    }

    &:hover {
      background-color: color('black');
      color: color('white');
    }
  }

  &--color-transparent-black-border {
    border-color: color('black');
    background-color: transparent;
    color: color('black');

    &:hover {
      background-color: color('black');
      color: color('white');
    }
  }

  &--color-transparent-gray-border {
    border-color: color('gray');
    background-color: transparent;
    color: color('gray');

    &:hover {
      color: color('dust-gray');
    }
  }

  &--color-transparent-dust-gray-border {
    border-color: color('dust-gray');
    background-color: transparent;
    color: color('dust-gray');
    opacity: 0.8;
  }

  &--color-secondary-gray-selected {
    border-color: color('gray');
    background-color: transparent;
    color: color('gray');
  }

  &--color-secondary-gray-unselected {
    border-color: color('gray');
    background-color: color('gray');
    color: color('black');
  }

  &--color-secondary-black-selected {
    border-color: color('black');
    background-color: transparent;
    color: color('black');
  }

  &--color-secondary-black-unselected {
    border-color: color('black');
    background-color: color('black');
    color: color('gray');
  }

  &--style-none {
    font-weight: 400;
    border: 0;
    padding: 0;
    background-color: transparent;
  }

  &--hover-down-small {
    transition: $transition;

    &:hover {
      transform: translate3d(0, 0.1rem, 0);
    }
  }

  &--font-akkurat {
    font-family: $akkurat;
  }

  &--font-nav {
    font-family: $futura-pt;
    font-weight: 500;
    font-size: 1.125rem;

    @media (query(md-up)) {
      font-size: 1.5rem;
    }
  }

  &--font-blog-nav {
    font-family: $futura-pt;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: 2px;

    @media (query(md-up)) {
      font-size: 2.375rem;
      line-height: 3.25rem;
    }
  }

  &--disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &--submitting {
    pointer-events: none;
    animation-timing-function: ease;
    animation: button-blink 1.5s infinite;
    background-color: color('gray');
    border-color: color('gray');
  }
}

.ViewShopButton {
  border-color: black;
  color: black;
  background-color: white;

  &:hover {
    color: white;
    background-color: black;
  }
}                                     
