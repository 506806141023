.Menu {
  visibility: hidden;
  opacity: 0;
  transition: $transition-short;
  min-width: 100vw;
  min-height: 100vh;
  overflow: scroll;

  &--active {
    visibility: visible;
    opacity: 1;
    display: flex;
  }

  &__close-icon {
    width: 1.5rem;
    margin-left: auto;

    @include media('md-up') {
      width: 2.5rem;
    }
  }

  &__link {
    transition: $transition-short;
    text-decoration: none;
    color: color('white');
    font-size: 1.75rem;
    font-weight: 500;
    text-transform: lowercase;
    margin: 3vh 0;

    @include media('md-up') {
      font-size: 2.375rem;
      margin: 1rem 0;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &__link--active {
    color: color('cloud-gray');
  }

  &__logo {
    opacity: 0.15;
    width: 3rem;

    @media (query(md-up)) {
      width: 4rem;
    }
  }
}
