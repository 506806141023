.TextField {
  label {
    display: block;
  }

  input,
  textarea {
    @extend .courier;
    @extend .input-text;
    border: 0;
    background: transparent;
    width: 100%;
    appearance: none;

    &::placeholder {
      @extend .courier;
    }
  }

  &--style-border-bottom {
    border-bottom: 0.1rem solid;
    padding: 0.2rem 0.5rem;
  }

  &--color-white {
    border-color: color('white');

    input,
    textarea {
      color: color('white');
      caret-color: color('white');

      &::placeholder {
        color: color('white');
      }
    }
  }

  &--color-gray {
    border-color: color('gray');

    input,
    textarea {
      color: color('gray');
      caret-color: color('gray');

      &::placeholder {
        color: color('gray');
      }
    }
  }

  &--color-black {
    border-color: color('black');

    input,
    textarea {
      color: color('black');
      caret-color: color('black');

      &::placeholder {
        color: color('black');
      }
    }
  }
}
