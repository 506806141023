$z: (
  'below': -1,
  'default': 0,
  'above': 1,
  'above_2': 2,
  'nav': 800,
  'overlay': 900
);

@function z($index) {
  @return map-get($z, $index);
}

@each $name, $z-index in $z {
  .z-#{$name} {
    z-index: #{$z-index};
  }
}
