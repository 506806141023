.BlockText {
  &:last-of-type {
    @extend .last-block;
  }

  &--mobile-center {
    text-align: center;

    @media (query(md-up)) {
      text-align: left;
    }
  }

  &--desktop-center {
    @media (query(md-up)) {
      text-align: center;
    }
  }

  &--text-align-left {
    p {
      text-align: left;
    }
  }

  h2 {
    @extend .block-headline;
    margin: 1rem 0;

    @media (query(md-up)) {
      margin: 2rem 0;
    }
  }

  p {
    @extend .description;
    margin: 0.5rem 0;

    @media (query(md-up)) {
      margin: 1rem 0;
    }
  }

  blockquote {
    font-style: italic;
    padding-left: 1rem;
    margin: 1.5rem 0;
    border-left: 2px solid color('light-gray');
    @media (query(md-up)) {
      padding-left: 2rem;
      margin: 2.5rem 0;
    }
  }
}
