.Article {
  &__back-button {
    width: 1rem;
    margin-top: calc(var(--nav-height) + 1.06rem);
    @media (query(md-up)) {
      width: 1.75rem;
      margin-top: 8rem;
    }
  }
  &__tag-link-wrapper {
    max-width: 44rem;
    display: flex;
    gap: 0.75rem;

    @media (query(md-up)) {
      font-size: 0.75rem;
      line-height: 1.25rem;
      gap: 1rem;
    }
    a {
      color: black;
      text-decoration: none;
      flex-shrink: 0;
      text-transform: uppercase;
      letter-spacing: 1px;
      transition: color 0.1s;
      font-size: 0.75rem;
      line-height: 1.25rem;

      div {
        font-weight: 500;
      }
      &:hover {
        color: color('dust-gray');
      }

      @media (query(md-up)) {
        font-size: 1.125rem;
      }
    }
  }
}
