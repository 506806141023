.search-block {
  width: 100%;

  .body {
    width: 100%;
    margin: 0 auto;
  }

  .search-block__form {
    padding: 2.31rem 1rem 17.75rem;
    display: flex;
    flex-direction: column;
    gap: .94rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 750px) {
      max-width: 62.6rem;
      padding: 10.5rem 2rem 16.44rem;
      flex-direction: row;
    }
  }

  .search-input-wrapper {
    flex: 1;
    position: relative;
    margin-bottom: 0;
  }

  .search-input {
    position: relative;
    color: color('black');
    font-family: $futura-pt;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.0225rem;

    width: 100%;
    height: 5.375rem;
    padding: 2.62rem .88rem 1.25rem;
    background-color: transparent;
    border: 1px solid;
    border-color: color('dark-gray');
    border-radius: 0;
    transition: border-color 0.2s ease;

    &:focus {
      border-color: inherit;
    }

    &--error {
      border-color: var(--color-error);
      
      &:focus {
        border-color: var(--color-error);
      }
    }

    @media (min-width: 750px) {
      padding: 2.5rem .88rem 1.06rem;
      font-size: 1.375rem;
      letter-spacing: 0.0275rem;
    }
  }

  .search-input-label {
    color: var(--color-gray);
    font-family: $akkurat;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 1.3125rem */

    position: absolute;
    top: 0.69rem;
    left: 0.88rem;

    @media (min-width: 750px) {
      font-size: 1rem;
    }
  }

  .search-input-error {
    position: absolute;
    left: 0;
    top: -1.25rem;
    bottom: unset;
    color: var(--color-error);
    font-size: 0.875rem;
    line-height: 1.2;

    @media (min-width: 750px) {
      top: unset;
      bottom: -1.25rem;
    }
  }

  // remove native 'x' icon from search input
  /* clears the 'X' from Internet Explorer */
  input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
  input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
  /* clears the 'X' from Chrome */
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }

  .search-submit {
    width: 100%;
    height: 3.125rem;
    display: flex;
    padding: 0.5rem 1.5rem;
    justify-content: center;
    align-items: center;

    color: color('medium-gray');
    font-family: $futura-pt;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.0225rem;


    background-color: color('white');
    border: 1px solid color('white');
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover:not(:disabled) {
      border-color: color('medium-gray');
      color: color('black');
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    @media (min-width: 750px) {
      width: 13.1875rem;
      height: auto;
      font-size: 1.375rem;
      letter-spacing: 0.0275rem;
    }
  }
}

// Dark mode styles
.search-block.bg-color-dark-gray {
  .search-input {
    color: color('white');
    background-color: transparent;
    border-color: color('white-opacity-04');
    
    &:focus {
      border-color: color('white-opacity-08');
      opacity: 1;
    }
  }

  .search-input-label {
    color: color('white-opacity-08');
  }

  .search-submit {
    background-color: color('charcoal');
    border-color: color('charcoal');
    color: color('white');

    &:disabled {
      opacity: 0.2;
    }

    &:hover:not(:disabled) {
      background-color: color('white');
      border-color: color('white');
      color: color('medium-gray');
    }

    &:focus {
      background-color: color('charcoal');
      border-color: color('charcoal');
      color: color('white');
    }
  }

  .search-input--error {
    border-color: var(--color-error-light);
  }

  .search-input-error {
    color: var(--color-error-light);
  }
}
