.background-media {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100lvh; 
  overflow: hidden;
  transition: opacity 0.5s ease-out;

  &__header {
    height: 25rem;

    @media (min-width: 750px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 36.035rem;
      overflow: hidden;
      transition: opacity 0.5s ease-out;
    }
  }

  &.opacity-0 {
    transition-delay: .2s;
  }

  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .background-video {
    width: auto;
    height: 100%;
    aspect-ratio: 16 / 9;
    position: relative;
    overflow: hidden;

    video {
      object-fit: cover;
    }
  }

  .youtube-player iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }

  @media (min-aspect-ratio: 16/9) {
    .youtube-player iframe {
      /* height = 100 * (9 / 16) = 56.25 */
      height: 56.25vw;
    }
  }
  // @media (max-aspect-ratio: 16/9) {
  //   .youtube-player iframe {
  //     /* width = 100 / (9 / 16) = 177.777777 */
  //     width: 177.78vh;
  //   }
  // }
}