.simpleSelect {
  width: 100%;
  position: relative;
  select {
    width: 100%;
    font-family: $akkurat;
    font-size: 16px;
    border: 1px solid;
    border-radius: 0;
    border-color: black;
    background-color: transparent;
    padding: 5px 10px 5px;
    appearance: none;
    position: relative;
    color: black;

    @media (query(md-up)) {
      font-size: 20px;
      padding: 5px 10px 2px;
      border: 2px solid;
    }
  }
  &:after {
    content: '';
    z-index: 10;
    display: block;
    position: absolute;
    right: 13px;
    top: 7px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border: 1px solid;
    border-width: 0 1px 1px 0;

    @media (query(md-up)) {
      right: 15px;
      top: 11px;
      width: 10px;
      height: 10px;
      border-width: 0 2px 2px 0;
    }
  }
}
