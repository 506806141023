$font-weights: (
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
);

@each $name, $font-weight in $font-weights {
  .font-weight-#{$name} {
    font-weight: #{$font-weight};
  }
}