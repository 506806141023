.wh100 {
  width: 100%;
  height: 100%;
}

.nowrap {
  white-space: nowrap;
}

.min-vh100 {
  min-height: 100vh;
}

.text-decoration-none {
  text-decoration: none;
}

.text-center {
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

.border {
  border: solid red;
}
