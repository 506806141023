.text-block {
  display: flex;
  flex-direction: column;

  position: relative;
  z-index: z('above_2');

  .body-content {
    width: 100%;
    max-width: 30rem;
    height: 100%;
    margin: 1.75rem auto 100px;

    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.51rem;

    @media (min-width: 750px) {
      margin: 6rem auto 100px;
    }
  }

  .foreground-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .foreground-media {
    width: 100%;
    max-width: 15rem;

    @media (min-width: 750px) {
      max-width: unset;
    }
  
    .foreground-image {
      width: 100%;
      margin: 0 auto;
    }

    .foreground-video {
      width: 100%;
      margin: 0 auto;
    }
  }

  .foreground-cta {
    width: fit-content;
    margin: 30px auto 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
  }

  .play-button {
    width: 20px;
    height: 20px;
    margin-top: 1px;
  }

  .label {
    color: inherit;
    font-family: $akkurat;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: 1px;
    text-underline-offset: 20%; /* 5.6px */
    text-underline-position: from-font;

    white-space: pre-wrap;
    text-align: left;

    @media (min-width: 750px) {
      font-size: 1rem;
    }

    @media (min-width: 2000px) {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding: 0 1rem;

    @media (min-width: 750px) {
      padding: 0;
    }

    p {
      font-family: $akkurat;
      font-size: 0.9375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 1.40625rem */
    }
  }

  .cta-wrapper {
    width: 100%;
    padding: 0 1rem;

    @media (min-width: 750px) {
      padding: 0;
    }
  }

  .cta-button {
    width: 100%;
    font-family: $akkurat;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.40625rem */

    @media (min-width: 750px) {
      font-size: 1rem;
    }
  }
}

.label-underline-light {
  text-decoration-color: color('white-opacity-04');

  &:hover {
    text-decoration-color: color('white');
  }
}

.label-underline-dark {
  text-decoration-color: color('medium-gray-opacity-04');

  &:hover {
    text-decoration-color: color('black');
  }
}