/*
AkkuratLL
*/
@font-face {
  font-family: AkkuratLL;
  src: url('./fonts/AkkuratLL-Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: AkkuratLL;
  src: url('./fonts/AkkuratLL-Regular.otf') format('opentype');
  font-weight: 400;
}
