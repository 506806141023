.Dropdown {
  .Select__control {
    border: none;
    border-bottom: solid 0.2rem color('black');
    border-radius: 0;

    &:hover {
      border-bottom: solid 0.2rem color('black');
    }
  }

  .Select--is-disabled, .Select__control--is-disabled {
    background-color: transparent;
    opacity: 0.5;
  }

  .css-1g6gooi,
  .Select__input {
    width: 100%;
    height: 100%;
  }

  input {
    width: 100% !important;
    font-family: $futura-pt !important;
    font-weight: 500 !important;
    font-size: 1.5rem !important;
    color: color('black') !important;

    @media (query(md-up)) {
      font-size: 2.125rem !important;
    }
  }

  .Select__value-container {
    padding: 0rem 1.8rem;

    @media (query(md-up)) {
      padding: 0rem 3rem;
    }
  }

  .Select__placeholder,
  .Select__option,
  .Select__single-value {
    @extend .futura-pt;
    font-weight: 500;
    color: color('black');
    font-size: 1.5rem;

    @media (query(md-up)) {
      font-size: 2.125rem;
    }
  }

  .Select__placeholder {
    color: color('gray');
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__dropdown-indicator {
    position: absolute;
    left: -0.5rem;
    bottom: 0;
    width: 100%;
    max-width: 2rem;
    margin: auto 0.5rem;
    cursor: pointer;

    @media (query(md-up)) {
      max-width: 2.4rem;
      margin: auto 1rem;
    }
  }

  .Select__menu {
    border: solid 1px color('gray');
    border-radius: 0;
    box-shadow: none;
  }

  .Select__option {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .Select__option--is-focused {
    background-color: rgba(133, 133, 133, 0.089);
  }

  .Select__option--is-selected {
    background-color: rgba(133, 133, 133, 0.26);
  }

  &--color-white {
    .Select__control {
      border-bottom: solid 0.2rem color('white');
      background-color: transparent;
    }

    .Select__placeholder,
    .Select__option,
    .Select__single-value {
      color: color('white');
    }

    .Select__menu {
      background-color: color('black');
    }
  }
}
