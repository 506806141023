//tostify styles
.Toastify {
  &__toast-container {
    top: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    left: 0 !important;
  }

  &__toast {
    height: 6rem;
    box-shadow: none !important;
    background-color: color('light-gray') !important;
  }

  &__close-button {
    display: none;
  }

  &__toast-body {
    font-size: 1.125rem;
    font-family: $akkurat;
    text-align: center;
    display: inline-block;
    color: color('black');
    display: flex;
    align-items: center;
    justify-content: center;

    @media (query(md-up)) {
      font-size: 1.5rem;
    }
  }
}
