.CompatibilityCheck {
  &__dropdown {
    max-width: 26rem;
  }

  &__text {
    max-width: 24rem;
  }

  &__text--sm {
    @media (query(md-up)) {
      max-width: 32rem;
    }
  }

  &__text--md {
    @media (query(md-up)) {
      max-width: 44rem;
    }
  }

  &__text--lg {
    @media (query(md-up)) {
      max-width: 50rem;
    }
  }

  &__variant-container {
    overflow: hidden;
    height: 0;
    transition: opacity $transition-duration-medium ease-out;
    opacity: 0;
  }

  &__variant-container--active {
    height: auto;
    opacity: 1;
  }

  .disabled {
    opacity: 0.5;
  }
}
