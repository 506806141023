.Nav {

  .nav-inner-wrapper {
    padding: .75rem .75rem .75rem 1rem;

    @media (min-width: 750px) {
      padding: 1.5rem 1.5rem 1.5rem 2rem;
    }

    @media (min-width: 2000px) {
      padding: 2rem 2rem 2rem 3rem;
    }
  }

  .logo {
    width: 48px;

    @media (min-width: 750px) {
      width: 60px;
    }
  }

  &--hide-buy {
    flex-direction: row-reverse;
  }

  .nav-menu-item {
    font-size: 1.125rem;

    @media (min-width: 750px) {
      font-size: 1.375rem;
    }
  }

  .nav-menu-item-hover {
    &:hover {
      text-decoration: underline;
    }
  }
}
