.Footer {
  &__link {
    @extend .text-center;
    color: color('white');
    transition: $transition-short;
    font-family: $futura-pt;
    font-weight: 500;
    text-decoration: none;
    font-size: 1.75rem;
    margin: 1rem 2.5rem;
    text-transform: lowercase;

    @media (query(md-up)) {
      font-size: 1.5rem;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  &__link-small {
    transition: $transition-short;
    color: color('white');
    text-transform: lowercase;

    &:hover {
      opacity: 0.5;
    }
  }

  &__link--active {
    color: color('cloud-gray');
  }

  &__logo {
    width: 3rem;

    @media (query(md-up)) {
      width: 4rem;
    }
  }

  &__social-icon-container {
    max-width: 10rem;

    @media (query(sm-up)) {
      max-width: 50rem;
    }
  }

  &__social-icon {
    max-width: 1.4rem;
    max-height: 1.4rem;
    width: auto;
    height: auto;
  }
}
