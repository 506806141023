:root {
  --nav-height: 59px;
  --site-wide-banner-height: 57px;
  --color-error: #dc3545;
  --color-error-light: #ff6b6b;

  @media (min-width: 750px) {
    --nav-height: 96.8px;
  }

  @media (min-width: 2000px) {
    --nav-height: 112.8px;
  }
}

.viewport-min-height-nav-offset {
  min-height: calc(100vh - var(--nav-height));
}

*:focus {
  outline-color: color('cloud-gray');
}

.not-allowed {
  cursor: not-allowed;
}

.block-container-width {
  max-width: 82rem;
}

.block-container-width-sm {
  max-width: 67.75rem;
}

.text-content-width-sm {
  max-width: 20rem;
}

.text-content-width-md {
  max-width: 25rem;
}

.single-column-content-width-sm {
  max-width: 32rem;
}

.single-column-content-width-md {
  max-width: 44rem;
}

.single-column-content-width-lg {
  max-width: 50rem;
}

.half-block-content-width {
  max-width: 25rem;

  @media (query(md-up)) {
    max-width: 100%;
  }
}

.block-image-width {
  max-width: 20rem;

  @media (query(md-up)) {
    max-width: 100%;
  }
}

.icon {
  width: 3rem;

  @media (query(md-up)) {
    width: 5rem;
  }
}

.icon-sm {
  width: 3rem;

  @media (query(md-up)) {
    width: 3rem;
  }
}

.scroll-down-icon {
  width: 1rem;

  @media (query(md-up)) {
    width: 1.5rem;
  }
}

.last-block {
  padding-bottom: 12rem;
}

.transition {
  transition: $transition;
}

.transition-short {
  transition: $transition-short;
}

.transition-long {
  transition: $transition-long;
}

.checkout-button {
  width: 100%;
  display: flex;
  justify-content: center;

  @include media('md-up') {
    width: 10rem;
  }
}

//Style for Cookieconsent library.
.cc-window {
  transition: $transition-medium;
  padding: 2rem 0.5rem 2.5rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;

  @media (query(md-up)) {
    flex-direction: row;
    padding: 3rem;
  }
}

.cc-message {
  @extend .description;
  text-align: center;
  margin-bottom: 1rem;

  @media (query(md-up)) {
    margin-bottom: 0;
  }
}

.cc-btn {
  cursor: pointer;
  font-family: $futura-pt;
  font-weight: 500;
  color: color('white');
  font-size: 1.5rem;
  padding: 0.25rem 0.7rem;
  margin: 0 auto;
  border: 0.125rem solid color('white');

  @media (query(md-up)) {
    background-color: transparent;
    color: color('black');
    font-size: 2.125rem;
    padding: 0.5rem 0.75rem;
    margin: 0;
    margin-left: 1.5rem;
  }
}

.disabledCursor {
  cursor: not-allowed;
}

.cc-link {
  opacity: 1;
}

.cc-invisible {
  pointer-events: none;
  opacity: 0;
}

.vertical-alignment-text-bottom {
  vertical-align: text-bottom;
}

.no-flex-shrink {
  flex-shrink: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// page fade in
.pageFadeIn {
  animation: fadeInAnimation ease 0.75s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}
