.BlockVideo {
  &__auto-play-poster-image {
    height: 100vh;
    width: 100vw;
  }

  &__overlay-image {
    width: 8rem;

    @include media('md-up') {
      width: 13rem;
    }
  }

  &__video-wrapper--full iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  }

  /* for full screen non-vimeo video source */
  &__video-wrapper--full video {
    position: relative;
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  }

  &__video-wrapper {
    position: relative;
    overflow: hidden;
  }

  &__video-wrapper > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  &__poster-image--inactive {
    opacity: 0;
  }

  &__small-video {
    &:last-of-type {
      @extend .last-block;
    }
  }

  &__button-image {
    max-width: 70px;

    @include media('md-up') {
      max-width: 100px;
    }
  }
}
