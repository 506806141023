.EmailSignup {
  &__info-text {
    @extend .transition-short;
    opacity: 1;
  }

  &__info-text--inactive {
    @extend .transition-short;
    opacity: 0;
  }

  &__button {
    max-width: 6rem;
  }

  &--secondary-detail {
    font-weight: 500;
    font-size: 0.75rem;
  }

  &--secondary-input {
    input,
    textarea {
      font-size: 1rem;
    }
  }
}
