.Radio {
  flex-direction: row;
  text-align: left;
  align-items: center;

  &__checked,
  &__unchecked {
    transition: $transition;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto 0;
    width: 0.6875rem;

    @media (query(md-up)) {
      width: 0.875rem;
    }
  }

  label {
    color: color('black');
    margin-left: 1.5rem;
    white-space: normal;
    text-wrap: balance;
  }

  &--secondary {
    .Radio__checked,
    .Radio__unchecked {
      transition: $transition;
      top: 0;
      left: 0;
      width: 0.6875rem;
      margin: 0.4rem 0;

      @media (query(md-up)) {
        width: 0.875rem;
        margin: 0.3rem 0;
      }
    }
  }

  &--gray {
    label {
      color: color('gray');
    }

    &::before {
      border-color: color('gray');
    }
  }
}
