.ProductListing__grid {
  max-width: 1160px;
  margin: 0 auto;
  padding: 30px 18px 36px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 18px;

  a {
    color: inherit;
    text-decoration: none;
  }

  @media (query(md-up)) {
    grid-template-columns: repeat(2, 1fr);
    padding-top: 60px;
    grid-gap: 24px;
  }

  @media (query(lg-up)) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.ProductListingItem {
  font-family: $akkurat;
  &__info {
    padding-top: 15px;
  }

  &__title {
    font-size: 18px;
  }

  &__subtitle {
    margin-top: 0.2em;
    font-size: 14px;
  }

  &__price {
    margin-top: 0.5em;
    font-size: 18px;
    font-weight: 800;
  }

  &__compareAtPrice {
    margin: 0 0.5rem 0 0;
    text-decoration: line-through;
  }
}

.ProductListingItem__images--hoverable {
  > * {
    transition: opacity 0.2s;
  }
  &:hover {
    .ProductListingItem__hoverImage {
      opacity: 1;
    }
    .ProductListingItem__featuredImage {
      opacity: 0;
    }
  }
}

.ProductListingItem__hoverImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
