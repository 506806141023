.product-block {
  display: flex;
  flex-direction: column;
  gap: 6rem;
  padding-bottom: 2.3rem;

  @media (min-width: 750px) {
    padding-bottom: 6.5rem;
  }

  // Mobile layout
  .product-tabs {
    display: none;

    @media (max-width: 749px) {
      display: block;
    }
  }

  // Desktop layout
  .products {
    display: none;
    width: 80%;
    height: 100%;
    margin: 0 auto;
    flex: 1;
    flex-direction: row;
    justify-content: center;

    @media (min-width: 750px) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      row-gap: 34px;
    }

    .product {

      .product-image {
        width: 50%;
        height: auto;
        margin: 0 auto 1.875rem;
      }

      .product-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .product-title {
          color: inherit;
          font-family: $futura-pt;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.36px;
          margin-bottom: 15px;
          padding: 0;
          text-align: left;
          white-space: wrap;
          background-color: transparent;

          @media (min-width: 750px) {
            font-size: 22px;
            letter-spacing: 0.44px;
          }
        }

        .product-description {
          display: block;
          font-family: $akkurat;
          font-size: 0.9375rem;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          transition: 0.2s ease;

          @media (min-width: 750px) {
            font-size: 1rem;
          }
        }

        .product-ctas {
          margin-top: 34px;
          display: flex;
          flex-direction: row;
          gap: 15px;
        }
      }
    }
  }
}