.BlockHalfPage {
  &:last-of-type {
    @extend .last-block;
  }

  &--full-image {
    flex-direction: column;

    @media (query(md-up)) {
      flex-direction: row;
    }
  }

  &__container--mobile-reverse {
    flex-direction: column-reverse;

    @media (query(md-up)) {
      flex-direction: row;
    }
  }

  &__container--desktop-reverse {
    @media (query(md-up)) {
      flex-direction: row-reverse;
    }
  }

  &__container--align-middle {
    align-items: center;
  }

  &__content-wrapper--align-mobile-reverse {
    padding-top: 3rem;
    padding-bottom: 0;

    @media (query(md-up)) {
      padding-top: 0;
    }
  }

  &__container--full-image {
    @media (query(md-up)) {
      justify-content: flex-end;
    }
  }

  &__content-wrapper {
    @media (query(md-up)) {
      width: 50%;
      height: 100%;
    }
  }

  &__content-wrapper--align-middle {
    @media (query(md-up)) {
      padding: 0;
    }
  }

  &__content-wrapper--narrow {
    @media (query(md-up)) {
      width: 35%;
    }
  }

  &__image-content--full-image {
    width: 100%;
    height: 60vh;

    a div {
      display: block !important;
    }

    @media (query(md-up)) {
      position: absolute;
      width: 50%;
      height: auto;
      left: 0;
    }
  }

  &__image-content--full-image-reverse {
    @media (query(md-up)) {
      right: 0;
      left: auto;
    }
  }
}
