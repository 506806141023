.BlogIndex {
  width: 100%;
  margin: calc(var(--nav-height) + 4rem) auto 4rem auto;

  @media (query(md-up)) {
    margin: 6rem auto 4rem auto;
  }

  @media (query(lg-up)) {
    width: 80%;
    margin: 10rem auto;
  }

  @media (query(xxl-up)) {
    width: 60%;
  }

  ol li {
    list-style-type: none;
  }

  ol li {
    list-style-type: none;
  }

  &__article-item {
    a {
      color: inherit;
      text-decoration: none;
      width: 100%;
      display: block;
    }
    &:last-of-type {
      .ArticleItem {
        margin-bottom: 0px !important;
      }
    }
  }
  &__navigation {
    height: fit-content;
    a {
      color: black;
      text-decoration: none;
    }
    @media (query(lg-up)) {
      width: 30%;
      top: 10rem;
    }
  }
  &__dropdown {
    z-index: 100;
    width: 100%;
    max-width: 50rem;
    transform: translateX(-50%);
    left: 50%;
  }
  &__dropdown-select {
    background-color: white !important;
  }
  &__article-list {
    width: 100%;

    @media (query(lg-up)) {
      padding-left: 10%;
    }

    @media (query(xxl-up)) {
      width: fit-content;
    }
  }
  &__navigation-item {
    p {
      border-bottom: 2px solid transparent;
    }
    &:hover {
      p {
        color: color('dust-gray');
        border-bottom: 2px solid color('dust-gray');
      }
    }
    &--active {
      p {
        border-bottom: 2px solid black;
      }
    }
  }
  &__filter-item {
    @extend .label-xl;
    text-transform: lowercase;
    color: black;
    p {
      border-bottom: 1px solid transparent;
    }
    &:hover {
      p {
        color: color('dust-gray');
        border-bottom: 1px solid color('dust-gray');
      }
    }
    &--active {
      p {
        border-bottom: 1px solid black;
      }
    }
  }
}
