$mix-blend-modes: (
  normal: normal,
  multiply: multiply,
  screen: screen,
  overlay: overlay,
  darken: darken,
  lighten: lighten,
  color-dodge: color-dodge,
  color-burn: color-burn,
  hard-light: hard-light,
  soft-light: soft-light,
  difference: difference,
  exclusion: exclusion,
  hue: hue,
  saturation: saturation,
  color: color,
  luminosity: luminosity
);

@each $name, $mix-blend-mode in $mix-blend-modes {
  .mix-blend-mode-#{$name} {
    mix-blend-mode: #{$mix-blend-mode};
  }
}