.BlockHalfAndHalf {
  &:last-of-type {
    @extend .last-block;
  }

  &__content-container {
    width: 100%;

    @media (query(md-up)) {
      width: 50%;
    }
  }

  &__content-container--mobile-middle {
    align-items: center;
  }

  &__content-container--mobile-left {
    align-items: flex-start;
  }

  &__content-container--desktop-middle {
    @media (query(md-up)) {
      align-items: center;
    }
  }

  &__content-container--desktop-left {
    @media (query(md-up)) {
      align-items: flex-start;
    }
  }

  &__title--mobile-middle {
    text-align: center;
  }

  &__title--mobile-left {
    text-align: left;
  }

  &__title--desktop-middle {
    @media (query(md-up)) {
      text-align: center;
    }
  }

  &__title--desktop-left {
    @media (query(md-up)) {
      text-align: left;
    }
  }

  &__text--align-middle {
    text-align: center;
    padding: 0 2rem;

    @media (query(md-up)) {
      padding: 2rem 0;
    }
  }

  &__image {
    width: 50%;
  }

  &__image--wide {
    width: 90%;
  }
}
