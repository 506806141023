.SocialShareLinks {
  &__title {
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 1rem;
    @media (query(md-up)) {
      font-size: 1.125rem;
    }
  }
  &__copied-message {
    bottom: -60%;
    color: black;
  }
  &__copy-link {
    margin-top: -0.1rem;
  }
  &__icon {
    height: 2.5rem;
    width: 2.5rem;
  }
}
