.accordion-block {
  width: 100vw;
  height: 100%;

  .body {
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    position: relative;
    z-index: z('above_2');

    @media (min-width: 750px) {
      padding-bottom: 12rem;
    }
  }
}