#ShopView {
  padding: 45px 40px;
  max-width: 800px;
  margin: 0 auto;

  h1 {
    text-align: center;
    font-size: 1.8rem;
    margin-bottom: 35px;
  }
}

.collectionsNav {
  display: flex;
  flex-direction: row;
  justify-content: center;

  > * + * {
    margin-left: 45px;
  }
}

.ShopViewNavigator {
  padding-top: calc(var(--site-wide-banner-height) + 75px);

  @media (query(md-up)) {
    padding-top: 85px;
  }
}

.ShopViewNavigator__logotype {
  max-width: 580px;
  width: 100%;
  padding: 0 30px;
}

.ShopViewNavigator__nav--desktop {
  display: none;
  @media (query(md-up)) {
    display: flex;
    justify-content: center;
    padding-top: 40px;
  }
}

.ShopViewNavigator__nav--mobile {
  display: block;
  padding: 30px 30px 0;
  @media (query(md-up)) {
    display: none;
  }
}

.ShopViewNavigator__navItem {
  font-family: $akkurat;
  font-size: 18px;

  a {
    text-decoration: none;
    color: inherit;
  }

  &:nth-child(n + 2) {
    margin-left: 50px;
  }

  &.active {
    border-bottom: 2px solid;
  }
}
