.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.ModalPageView {
  width: 100%;
  min-height: 100vh;

  &__close-icon {
    width: 1.5rem;

    @media (query(md-up)) {
      width: 2.5rem;
    }
  }

  &__blocks-container {
    margin-bottom: 12rem;
  }

  &__logo {
    width: 3rem;

    @media (query(md-up)) {
      width: 5rem;
    }
  }

  .modal-inner-wrapper {
    width: 100%;

    @media (min-width: 750px) {
      width: 70%;
    }
  }
}
