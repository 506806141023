.Parallax {
  &__button {
    @include media('md-up') {
      margin-bottom: 7.5rem;
    }
  }
}

.jarallax-img {
  pointer-events: all !important;
}
