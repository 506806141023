.header-block {
  width: 100%;
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 6.31rem;

  @media (min-width: 750px) {
    height: 36.035rem;
    padding-bottom: 12.69rem;
  }

  .header-content {
    position: relative;
    z-index: z('above_2');
  }
}
