.testimonial-block {
  width: 100%;
  position: relative;
  z-index: z('above_2');
}

.testimonial-inner-wrapper {
  font-family: $akkurat;
  color: inherit;
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  padding: 0 24px;

  .disc {
    background-color: color('white');
    width: 0.6875rem;
    height: 0.6875rem;
    border-radius: 100%;
    flex-shrink: 0;
    margin-top: 0.25rem;
  }

  .testimonial__quote {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
  }

  .testimonial__attribution {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
}

.testimonialPosition {
  display: block;
  position: absolute;
  width: 100%;
  max-width: 368px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  @media (query(md-up)) {
    &--0 {
      top: 30%;
      left: 15%;
    }
  
    &--1 {
      top: 60%;
      left: 40%;
    }
  
    &--2 {
      top: 40%;
      left: 20%;
    }
  
    &--3 {
      top: 35%;
      left: 50%;
    }

    &--4 {
      top: 20%;
      left: 15%;
    }
  }
}

.active-testimonial {
  animation: fadeIn 0.5s linear forwards, verticalSlideIn 16s linear forwards, fadeOut 0.5s linear 7.5s forwards;
  
  @media (query(md-up)) {
    opacity: 0;
    animation: fadeIn 0.5s linear forwards, horizontalSlideToLeft 16s forwards, fadeOut 0.5s linear 7.5s forwards;
  }
}

.prev-testimonial {
  opacity: 0;
  animation: none;
  
  @media (query(md-up)) {
    transform: translateX(0);
  }
}

.inactive-testimonial {
  display: none;
}

@media (prefers-reduced-motion: reduce) {
  .active {
    animation: none;
    opacity: 1;
  }
  .prev {
    animation: none;
    opacity: 0;
  }
}

// Fade in and out Keyframes (Desktop and mobile)
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// Vertical Slide Up Keyframes (Mobile)
@keyframes verticalSlideIn {
  0% {
    transform: translate(-50%, 75%);
  }
  100% {
    transform: translate(-50%, -150%);
  }
}

// Horizontal Slide Keyframes (Desktop)
@keyframes horizontalSlideToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(50px);
  }
}