body {
  font-family: $futura-pt;
  font-display: swap;
}

ul {
  list-style: none;
}

em {
  font-style: italic;
}

strong {
  font-weight: 600;
}

.akkurat {
  font-family: $akkurat;
}

.futura-pt {
  font-family: $futura-pt;
}

.font-md {
  font-weight: 500;
}

.courier {
  font-family: $courier;
}

.italic {
  font-style: italic;
}

.block-headline {
  font-family: $futura-pt;
  font-size: 2rem;
  font-weight: 500;

  @media (query(md-up)) {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

.block-body-text {
  font-family: $futura-pt;
  font-size: 2rem;
  font-weight: 500;

  @media (query(md-up)) {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

.block-headline-sm {
  font-family: $futura-pt;
  font-size: 1.5rem;
  font-weight: 500;

  @media (query(md-up)) {
    font-size: 3rem;
    line-height: 4.3rem;
  }
}

.block-headline-secondary {
  font-family: $futura-pt;
  font-size: 1.5rem;
  font-weight: 500;

  @media (query(md-up)) {
    font-size: 2.375rem;
    line-height: 4.3rem;
  }
}

.block-headline-article-item {
  @extend .block-headline-secondary;

  @media (query(md-up)) {
    line-height: 3.25rem;
  }
}

.description {
  font-family: $futura-pt;
  font-size: 1rem;
  line-height: 1.2rem;

  @media (query(md-up)) {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
}

.nav-text {
  font-family: $futura-pt;
  font-weight: 700;
  font-size: 1.125rem;

  @media (query(md-up)) {
    font-size: 1.5rem;
  }
}

.detail {
  font-weight: 500;
  font-size: 0.75rem;

  @media (query(md-up)) {
    font-size: 1.25rem;
  }
}

.detail-spacing {
  letter-spacing: 0.03rem;
}

.input-text {
  font-size: 1rem;

  @media (query(md-up)) {
    font-size: 1.5rem;
  }
}

.label {
  font-size: 0.75rem;
  line-height: 1.25rem;

  @media (query(md-up)) {
    font-size: 0.875rem;
  }
}

.label-lg {
  font-size: 0.9rem;
  line-height: 1.2rem;
}

.label-xl {
  font-size: 1rem;
  line-height: 1.25rem;
}

.info-text {
  font-size: 0.875rem;
  line-height: 1.2rem;
}

.info-text-sm {
  font-size: 0.625rem;

  @media (query(md-up)) {
    font-size: 0.875rem;
  }
}

.title {
  font-size: 1.5rem;
}

.sub-title {
  font-size: 1.125rem;
}

.description-sm {
  font-size: 0.875rem;
}

.markdown-block {
  h2 {
    @extend .block-headline;
  }

  h3 {
    @extend .block-headline-sm;
  }

  h4 {
    @extend .block-headline-secondary;
  }

  p {
    @extend .description;
  }

  a {
    text-decoration: underline;
    color: color('white');

    &:hover {
      color: color('black');
    }
  }

  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  hr {
    @media (query(md-up)) {
      border: solid color('black') 1px;
    }
  }

  ul {
    list-style: disc;
  }

  ul li,
  ol li {
    @extend .description;
    margin-left: 1.2rem;

    @media (query(md-up)) {
      margin-left: 1.5rem;
    }
  }
}

.markdown-block--black {
  a {
    text-decoration: underline;
    color: color('black');

    &:hover {
      color: color('gray');
    }
  }
}

.markdown-block--black-on-white {
  a {
    &:hover {
      color: color('gray');
    }
  }
}

.markdown-block--black-on-gray {
  a {
    text-decoration: underline;
    color: color('black');

    &:hover {
      color: color('white');
    }
  }
}

.markdown-block--gray {
  a {
    text-decoration: underline;
    color: color('gray');

    &:hover {
      color: color('black');
    }
  }
}

.markdown-block--dust-gray {
  a {
    text-decoration: underline;
    color: color('dust-gray');

    &:hover {
      color: color('black');
    }
  }
}

.markdown-block-secondary {
  h2 {
    @extend .block-headline;
    font-family: $akkurat;
  }

  h3 {
    @extend .block-headline-sm;
    font-family: $akkurat;
  }

  h4 {
    @extend .block-headline-secondary;
    font-family: $akkurat;
  }

  p {
    @extend .info-text;
    font-family: $akkurat;
  }

  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }

  hr {
    @media (query(md-up)) {
      border: solid color('black') 1px;
    }
  }
}
