.ShopifyProductImages__desktop {
  display: none;

  button {
    outline: 0;
    border: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    background-color: transparent;
  }

  @media (query(md-up)) {
    display: block;
    > *:nth-child(n + 2) {
      margin-top: 30px;
    }
  }
}

.ShopifyProductImages__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  overflow: scroll;

  &-image {
    max-width: calc(100% - 60px);
    max-height: 85vh;
    margin: 0 auto;

    img {
      max-height: 85vh;
      object-fit: contain;
    }

    & + & {
      margin-top: 60px;
    }
  }
}

.ShopifyProductImages__mobile {
  padding: 40px 0 20px;
  height: calc(100vw + 5px);
  .flickity-page-dots .dot {
    border: 1px solid #7a7a77;
  }

  .flickity-page-dots .dot.is-selected {
    background-color: #7a7a77;
  }
  @media (query(md-up)) {
    display: none;
  }
}

.ShopifyProductImages__mobile__imageContainer {
  width: 100vw;
  padding: 0 40px;
}
