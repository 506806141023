.ShopifyProductDetail {
  font-family: $akkurat;
  max-width: 900px;
  margin: 0 auto;

  &__images {
    @media (query(md-up)) {
      padding-bottom: 60px;
    }
  }

  @media (query(md-up)) {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 320px;
    grid-gap: 30px;
    margin: 40px auto 0;
  }

  .ShopifyProductDetail__details {
    padding-bottom: 30px;
    margin: 0 2rem;

    p + p {
      margin-top: 1em;
    }

    @media (query(md-up)) {
      position: sticky;
      align-self: flex-start;
      top: 60px;
      padding-bottom: 60px;
      margin: 0 auto;
    }
  }

  .successMessage {
    padding-top: 0.5em;
    transition: 0.2s;
    opacity: 0;
    transform: translateY(-10px);

    a {
      color: inherit;
    }

    &--visible {
      opacity: 1;
      transform: initial;
    }
  }
}

.ShopifyProductDetail__details__price {
  font-weight: bold;
  margin-bottom: 0.4em;
}

.ShopifyProductDetail__details__validation_error {
  color: red;
}

.ShopifyProductDetail__details__subtitle {
  @media (query(md-up)) {
    font-size: 18px;
  }
}

.ShopifyProductDetail__details__price,
.ShopifyProductDetail__details__title {
  font-size: 17px;
  @media (query(md-up)) {
    font-size: 24px;
  }
}
.ShopifyProductDetail__details__options {
  > * {
    margin: 30px 0;

    @media (query(md-up)) {
      margin: 14px 0;
    }
  }
}
.ShopifyProductDetail__details__description {
  margin: 22px 0 40px;
}

.ShopifyProductDetail__details__links {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  a {
    font-size: 12px;
    color: black;
    text-decoration: none;
    border-bottom: 1px solid;

    @media (query(md-up)) {
      font-size: 18px;
    }
  }
}
