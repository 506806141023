.details-block {
  padding: 0 1rem;

  @media (min-width: 750px) {
    padding: 0 2rem;
  }

  @media (min-width: 2000px) {
    padding: 0 3rem;
  }

  .details-container {
    display: flex;
    flex-direction: column;
    border-top: 1px solid;
    border-color: color('gray');
  }

  .detailGroup {
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (min-width: 750px) {
      flex-direction: row;
      padding: 1.5rem 0;
      border-bottom: 1px solid;
      border-color: color('gray');
    }
  }

  .detail {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    padding: 1.5rem 0 2rem 0;
    border-bottom: 1px solid;   
    border-color: color('gray');

    @media (min-width: 750px) {
      width: 50%;
      min-height: 374px;
      padding: 0;
      border: none;

      &:not(:first-child) {
        padding-left: 2rem;
        border-left: 1px solid;
        border-color: color('gray');
      }
    }
  }

  .detail-title-link {
    display: block;
    width: fit-content;
    text-decoration-color: color('medium-gray-opacity-04');
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
    text-underline-position: from-font;

    &:hover {
      text-decoration-color: color('medium-gray');
    }

    @media (min-width: 750px) {
      text-decoration-thickness: 3px;
    }
  }

  .detail-title {
    color: inherit;
    font-family: $futura-pt;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    white-space: pre-wrap;

    @media (min-width: 750px) {
      text-wrap: balance;
      font-size: 2.125rem;
      letter-spacing: 0.02125rem;
      padding-right: 2rem;
    }

    @media (min-width: 2000px) {
      font-size: 3.125rem;
      letter-spacing: 0.0625rem;
    }
  }

  .detail-content {
    display: flex;
    flex-direction: column;
    gap: 2.31rem;
    width: fit-content;
  }

  .detail-body {
    font-family: $akkurat;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 1.40625rem */

    @media (min-width: 750px) {
      width: 100%;
      max-width: 31.875rem;
      padding-right: 2rem;
      font-size: 1rem;
    }
  }

  .detail-image {
    max-width: 10rem;
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .detail-list {
    display: flex;
    flex-direction: column;
  }

  .detail-item {
    font-family: $akkurat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    transition: transform 300ms;
    transition-timing-function: ease;

    @media (min-width: 750px) {
      font-size: 16px;
    }

    @media (min-width: 2000px) {
      font-size: 20px;
    }
  }

  .detail-list-fadeOut {
    overflow: visible;
    position: relative;
  }

  .detail-list-overlay {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #EDEDED 0%, rgba(237, 237, 237, 0.00) 100%);
    z-index: 5;
    transition: transform 300ms;
    transition-timing-function: ease;
  }

  .detail-links {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;

    .detail-link {
      font-size: 0.9375rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      text-decoration-line: underline;
      text-decoration-style: solid;
      text-decoration-skip-ink: none;
      text-decoration-color: color('medium-gray-opacity-04');
      text-decoration-thickness: 1px;
      text-underline-offset: 20%;
      text-underline-position: from-font;

      &:hover {
        color: color('black');
        text-decoration-color: color('black');
      }
    }
  }
}

body:has(.detail-list:hover) .detail-item {
  transform: translateY(-7px);
}

body:has(.detail-list:hover) .detail-list-overlay {
  transform: translateY(14px);
}

.details-block.bg-color-dark-gray {
  .details-container {
    border-color: color('white-opacity-02');
  }

  .detail {
    border-color: color('white-opacity-02');

    @media (min-width: 750px) {
      &:not(:first-child) {
        border-color: color('white-opacity-02');
      }
    }
  }

  .detailGroup {
    border-color: color('white-opacity-02');
  }

  .detail-title-link {
    text-decoration-color: color('white-opacity-04');

    &:hover {
      color: color('white');
      text-decoration-color: color('white');
    }
  }

  .detail-link {
    text-decoration-color: color('white-opacity-04');

    &:hover {
      color: color('white');
      text-decoration-color: color('white');
    }
  }
}
