@import './colors';

$transition-duration: 0.2s;
$transition-duration-short: 0.125s;
$transition-duration-medium: 0.5s;
$transition-duration-long: 1s;
$transition-easing: cubic-bezier(0.25, 0.2, 0, 1);
$transition: $transition-duration $transition-easing;
$transition-short: $transition-duration-short $transition-easing;
$transition-medium: $transition-duration-medium $transition-easing;
$transition-long: $transition-duration-long $transition-easing;

@keyframes button-blink {
  0% {
    background: #c6c5c1;
    border-color: #c6c5c1;
  }

  35% {
    background: darken(#c6c5c1, 10%);
    border-color: darken(#c6c5c1, 10%);
  }

  50% {
    border-color: #c6c5c1;
    background: #c6c5c1;
  }

  100% {
    background: #c6c5c1;
    border-color: #c6c5c1;
  }
}

@keyframes form-blink {
  0% {
    color: #c6c5c1;
    border-color: #c6c5c1;
  }

  35% {
    color: darken(#c6c5c1, 10%);
    border-color: darken(#c6c5c1, 10%);
  }

  50% {
    border-color: #c6c5c1;
    color: #c6c5c1;
  }

  100% {
    color: #c6c5c1;
    border-color: #c6c5c1;
  }
}

@keyframes image-blink {
  0% {
    opacity: 0.3;
  }

  35% {
    opacity: 0.8;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

.fadeIn {
  animation-name: fade-in;
}

.fadeOut {
  animation-name: fade-out;
}

@keyframes moveForward {
  0% {
    width: 0;
  }
  50% {
    width: 60%;
  }
  60% {
    width: 75%;
  }
  65% {
    width: 80%;
  }
  75% {
    width: 88%;
  }
  90% {
    width: 90%;
  }
  100% {
    width: 90%;
  }
}
