.Faq {
  &__question {
    text-align: left;
    white-space: normal;

    &:hover {
      opacity: 0.5;
    }
  }

  &__answer {
    overflow: hidden;
    transition: all 0.5s ease;
    transform: translateY(-10%);
    max-height: 0;
    opacity: 0;
  }

  &__answer--active {
    transform: translateY(0);
    max-height: none;
    opacity: 1;
  }
}
