.Devices {
  width: fit-content;
  margin: 1rem auto 2rem;
  display: flex;
  flex-direction: row;

  @media (query(md-up)) {
    width: 80%;
    margin: 2rem auto 4rem;
  }
}

.Device {
  transition: opacity ease-in-out;

  button {
    display: block;
  }

  &--notActive {
    opacity: 0.5;
  }

  &__inner {
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin-bottom: .5rem;

    @media (query(md-up)) {
      margin-bottom: 1rem;
    }
  }

  &__thumbnail-image {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;

    @media (query(md-up)) {
      width: 60%;
    }
  }
}

.Device:hover {
  opacity: 1;
}