.Cart {
  font-family: $akkurat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 500px;
  padding: 0 20px;
  margin: 0 auto;

  a {
    color: inherit;
  }
}

.Cart__empty {
  height: 500px;
  max-height: 80vh;
}

.Cart__summary {
  width: 100%;

  &__header {
    padding-top: calc(1.5rem + 28px);
    padding-bottom: 1rem;
  }
}

.Cart__totals {
  padding-top: 4px;
  width: 75%;
  @media (query(md-up)) {
    width: 50%;
  }
  margin-left: auto;
}

.Cart__totals__line:last-child {
  border-top: 2px solid;
  padding-top: 0.8em;
}

.Cart__lineItems {
  border: 2px solid #222;
  border-width: 2px 0;
  padding: 1rem 0;
}

.Cart__lineItem {
  padding: 1rem 0;
  display: grid;

  grid-template-columns: 1fr 70px 90px;
  @media (query(md-up)) {
    grid-template-columns: 1fr 90px 120px;
  }
}

.Cart__lineItemQuantity {
  display: flex;
  align-self: baseline;
  font-size: 1.125rem;

  &--disabled {
    pointer-events: none;
    color: gray;
  }

  &__quantity {
    padding: 0 0.5em;
  }

  button {
    border: 0;
    background-color: transparent;
    // outline: 1px solid grey;
    display: inline;
    cursor: pointer;
    padding: 0 0.5em;
    margin-top: -0.3em;
  }
}

.Cart__ctas {
  padding-top: 2rem;
  display: flex;

  justify-content: center;

  & > * + * {
    margin-left: 1rem;
  }
}

.Cart__details__links {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;

  a {
    font-size: 12px;
    color: black;
    text-decoration: none;
    border-bottom: 1px solid;

    @media (query(md-up)) {
      font-size: 18px;
    }
  }
}
