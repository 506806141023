.FormInput {
  input {
    @extend .akkurat;
    font-size: 1.125rem;
    border: none;
    border-radius: 0;
    border-bottom: solid 2px color('black');
    padding: 0.7rem 0;
  }

  &--disabled {
    pointer-events: none;

    .FormInput__label,
    input {
      color: color('gray');
      border-color: color('gray');
    }
  }

  &--submitting {
    pointer-events: none;

    .FormInput__label,
    input {
      animation-timing-function: ease;
      animation: form-blink 1.5s infinite;
      color: color('gray');
      border-color: color('gray');
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
}
