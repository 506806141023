.LightPhoneFlowView {
  overflow: scroll;
  transition: all $transition-duration;
  // padding-top: 3.5rem;

  &__no-overflow {
    overflow: hidden;
  }

  @media (query(md-up)) {
    min-height: 100vh;
  }

  &__back-icon {
    width: 0.7rem;

    @media (query(md-up)) {
      width: 1.5rem;
    }
  }

  &__product-info-container {
    overflow: none;
    height: 100%;
  }

  &__image-block {
    height: 50vw;
    min-height: 16rem;

    @media (query(md-up)) {
      height: auto;
      width: 100%;
      max-width: 50%;
    }
  }

  &__image-block__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.2s;

    &--selected {
      opacity: 1;
    }
  }

  &__description-container {
    min-height: 12rem;

    @media (query(md-up)) {
      width: 50%;
    }
  }
  /* Override default .Button--disabled styles.
   * In this context, those styles are incorrect. */
  .Button--color-white.Button--disabled {
    border-color: #fff;
    background-color: #fff;
    color: #222222;
    opacity: 0.3;
  }
  .Button--color-black.Button--disabled {
    border-color: #222222;
    background-color: #222222;
    color: #fff;
    opacity: 0.3;
  }

  .Button--disabled.Radio {
    background-color: initial;
    opacity: 0.6;
  }

  &__thumbnail-image-container {
    height: 20%;
    min-height: 8rem;
  }

  &__thumbnail-button {
    max-width: 10%;
    max-height: 60%;
  }

  &__thumbnail-image {
    max-width: 100%;
    max-height: 100%;
  }

  &__note {
    max-width: 23rem;

    @media (query(md-up)) {
      max-width: 100%;
    }
  }

  &__carousel {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;

    .flickity-page-dots .dot {
      border: 1px solid #7a7a77;
    }

    .flickity-page-dots .dot.is-selected {
      background-color: #7a7a77;
    }

    &__image {
      width: 50vw;
    }

    @media (query(md-up)) {
      display: none;
    }
  }
}

.product_description {
  h1, h2, h3 {
    line-height: 1;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  a {
    &:hover {
      color: color('dust-gray') !important;
    }
  }
}

.LightPhoneFlowView--phone {
  .LightPhoneFlowView__product-info-container {
    padding: 8rem 0 0 0;
    
    @media (query(md-up)) {
      height: 70%;
      min-height: 30rem;
      margin-top: 10%;
      padding: 0;
    }
  }
}

.LightPhoneFlowView--plan,
.LightPhoneFlowView--carbonOffset {

  .LightPhoneFlowView__product-info-container {
    max-width: 22rem;
    margin: 4rem 2rem;

    @media (query(md-up)) {
      margin: auto auto;
    }
  }

  &__description {
    p + p {
      margin-top: 0.5em;
    }
  }
}

.LightPhoneFlowView--accessories {
  .PhoneFlowAccessories {
    &__accessory {
      width: 20rem;
      max-width: 90vw;
    }
    &__images-container {
      height: 19rem;

      @media (query(md-up)) {
        height: 24rem;
      }
    }

    &__image-container {
      transition: opacity $transition-duration;
      opacity: 0;
    }
    &__image-container--selected {
      opacity: 1;
    }

    &__variant-selector-container {
      height: 40px;
    }

    &__image {
      max-width: 100%;
      max-height: 100%;
      opacity: 1;
      max-width: 100%;
    }

    &__radio-button--dark {
      color: color('light-gray');
    }
    &__radio-button--light {
      color: black;
    }
    &__radio-description {
      white-space: initial;
    }

    &__variant-color {
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 50%;
      border: solid 0.2rem;

      &--dark {
        color: color('light-gray');
      }

      &--selected {
        background-color: currentColor;
      }
    }
  }
}
