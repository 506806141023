.BlockFullImage {
  height: 100vh;

  &__text {
    max-width: 18rem;

    @include media('md-up') {
      max-width: 25rem;
    }
  }

  &__body {
    max-width: 18rem;

    @include media('md-up') {
      max-width: 50%;
    }
  }
}
