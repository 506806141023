.site-wide-banner {
  position: absolute;
  left: 50%;
  width: 100%;
  border-top: 1px solid;
  border-color: inherit;
  margin-top: var(--nav-height);
  padding: 1.06rem 0;
  transform: translateX(-50%);
  z-index: z('nav');
  text-align: center;

  @media (min-width: 750px) {
    width: fit-content;
    margin-top: 2.3rem;
    padding: 0;
    border: none;
  }

  a:link, a:visited, a:active {
    color: inherit;
  }
}

.site-wide-banner-label {
  width: 100%;
  font-family: $akkurat;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
