.Loader {
  &__bar {
    height: 0.2rem;
    width: 90%;
    background: color('gray');
    animation: moveForward 10s linear;

    @include media('md-up') {
      height: 0.3rem;
    }
  }

  &__image {
    opacity: 1;
    animation: image-blink 1.8s infinite;
    width: 6rem;

    @include media('md-up') {
      width: 10rem;
    }
  }
}
