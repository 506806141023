.CompatibilityCheckView {
  width: 100%;
  min-height: 100vh;

  &__close-icon {
    width: 1.5rem;

    @media (query(md-up)) {
      width: 2.5rem;
    }
  }

  &__logo-container {
    left: 50%;
    transform: translateX(-50%);
    margin: 1rem auto;

    @media (query(md-up)) {
      margin: 2rem auto;
    }
  }

  &__logo {
    display: block;
    width: 3rem;

    @media (query(md-up)) {
      width: 5rem;
    }
  }
}
